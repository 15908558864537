.footer-container {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  height: 65px;
  overflow: hidden;
  /* position: fixed;
    bottom: 0;
    left: 0;
    right: 0; */
  margin-top: 10px;
  z-index: 1; }

.footer-logo {
  width: 109px;
  height: 24px;
  margin-left: 24px;
  object-fit: contain; }

.footer-text {
  color: var(--grape-purple);
  opacity: 0.5;
  font-size: 14px;
  letter-spacing: 0.25px;
  margin-left: 60px;
  line-height: 24px; }

.footer-terms {
  color: var(--main-pink);
  margin-left: auto; }

.footer-links {
  margin-left: auto; }
  .footer-links span {
    margin-right: 32px; }

@media screen and (max-device-width: 480px) {
  .footer-container {
    display: none; } }
