.menu-container {
  background-color: #f0eef1;
  position: fixed;
  max-height: 0px;
  opacity: 0;
  z-index: -1;
  width: 100vw;
  pointer-events: none;
  right: -500px; }
  .menu-container .menu-link {
    width: 100%;
    display: flex;
    height: 66px;
    background-color: #ffffff;
    margin-bottom: 4px;
    padding: 0 20px;
    display: flex;
    align-items: center; }
  .menu-container .footer-text {
    color: var(--grape-purple);
    opacity: 0.5;
    font-size: 14px;
    letter-spacing: 0.25px; }
  .menu-container .footer-links {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-top: auto;
    padding: 15px; }
  .menu-container .footer-rights {
    display: flex;
    align-items: center;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 24px;
    margin-bottom: 15px; }
  .menu-container .logo-img {
    width: 109px;
    height: 24px; }

.menu-container-open {
  top: 66px;
  pointer-events: visible;
  opacity: 1;
  z-index: 9;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: flex-start;
  max-height: none;
  height: calc(100% - 66px) !important;
  right: 0;
  transition: 300ms right cubic-bezier(0.77, 0, 0.175, 1); }

.large-version {
  top: 88px;
  margin: 0;
  height: calc(100vh - 88px) !important; }
