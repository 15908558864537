@media screen and (max-device-width: 480px) {
  .floating-btn-container {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 66px;
    width: 50px;
    height: 50px;
    left: 22px;
    box-shadow: 0 0 11px rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
    position: fixed;
    bottom: 22px;
    z-index: 1; }
    .floating-btn-container.right {
      left: auto;
      right: 22px; }
    .floating-btn-container .add-new {
      font-size: 20px;
      font-weight: bold;
      color: var(--main-purple); }
  .print-icon {
    width: 25px;
    height: 15px;
    object-fit: contain; }
  .share-icon {
    width: 21px;
    height: 23px;
    object-fit: contain; }
  .dots-icon {
    width: 33px;
    height: 20px;
    object-fit: contain; } }
