.mobile-header {
  display: none; }

.container {
  height: 100px;
  position: relative;
  background-image: url("/static/images/rio-img.jpg"); }
  .container.on-profile {
    background-image: none; }
    .container.on-profile::before {
      background-color: #f7c258; }
  .container::before {
    content: '';
    background-color: rgba(71, 15, 99, 0.8);
    width: 100%;
    height: 100%; }
  .container .content {
    position: absolute;
    display: flex;
    height: 100%;
    width: 100%; }
    .container .content .full-logo {
      background: var(--main-yellow);
      padding: 0 15px 0 15px;
      height: 100%;
      display: flex;
      align-items: center; }
    .container .content .logo {
      background: var(--main-yellow);
      width: 100px;
      min-width: 100px;
      height: 100%;
      display: inline-block;
      text-align: center; }
      .container .content .logo img {
        padding-top: 28px;
        margin: auto; }
    .container .content .actions {
      height: 100%;
      display: flex;
      align-items: center;
      margin-left: auto;
      margin-right: 16px; }
      .container .content .actions .journey-btn .notification {
        background: #fff;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        line-height: 24px;
        text-align: center;
        margin-left: 14px;
        margin-right: -7px;
        margin-top: -4px;
        font-size: 12px;
        color: red;
        font-weight: bold;
        font-family: 'Open Sans', sans-serif; }
      .container .content .actions .auth-btn {
        font-weight: bold;
        margin-right: 15px;
        cursor: pointer; }

.weather-container {
  display: flex;
  align-items: center;
  height: 100px;
  margin-left: 20px;
  width: 1px;
  flex: 1 1 0; }
  .weather-container .weather-btn {
    min-width: 54px;
    padding: 0 14px;
    width: 65px; }
    .weather-container .weather-btn-close {
      background-color: white !important;
      box-shadow: 0 6px 6px 0 rgba(99, 86, 15, 0.1), 0 2px 0 0 white !important; }
  .weather-container .country-selector {
    border-right: solid 2px #753296;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    min-width: 175px;
    background-color: var(--main-yellow); }
    .weather-container .country-selector .left-arrow {
      margin-right: 11.5px; }
    .weather-container .country-selector .right-arrow {
      margin-left: 11.5px; }
    .weather-container .country-selector .flag-container {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 61px;
      height: 46px;
      border-radius: 4px;
      border: solid 4px rgba(117, 50, 150, 0.2); }
      .weather-container .country-selector .flag-container .flag {
        width: 61px;
        height: 46px; }
  .weather-container .weather-days-container {
    display: flex;
    height: 100%;
    overflow-x: auto;
    background-clip: text;
    -webkit-background-clip: text;
    transition: all 0.5s ease-in-out;
    transform: scaleX(0);
    transform-origin: left; }
    .weather-container .weather-days-container-is-open {
      margin-left: 15px;
      transform: scaleX(1); }
    .weather-container .weather-days-container .daily-container {
      flex-basis: auto;
      flex-grow: 1;
      padding-left: 12px;
      padding-right: 12px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      max-width: 110px;
      min-width: 110px;
      border-right: solid 2px #753296; }
      .weather-container .weather-days-container .daily-container:last-child {
        border-right: none; }
      .weather-container .weather-days-container .daily-container .day {
        display: flex;
        letter-spacing: 0.25px;
        color: #f7c258;
        font-size: 14px;
        font-weight: bold; }
        .weather-container .weather-days-container .daily-container .day .icon {
          height: 15px;
          margin-left: 15px; }
      .weather-container .weather-days-container .daily-container .date {
        display: inline-block;
        padding-top: 5px;
        color: #f7c258;
        font-size: 11px;
        letter-spacing: 0.2px; }
      .weather-container .weather-days-container .daily-container .temp-container {
        width: 100%;
        height: 19px;
        margin-top: 11px;
        display: flex;
        justify-content: flex-start; }
        .weather-container .weather-days-container .daily-container .temp-container .temp {
          font-size: 14px;
          font-weight: bold;
          letter-spacing: 0.25px;
          color: #f7c258; }
  .weather-container .close-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%; }

@media screen and (max-device-width: 480px) {
  .mobile-header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 66px;
    background-color: #f0eef1;
    pointer-events: all; }
    .mobile-header.menu-open {
      /* position: fixed;
      z-index: 2;
      top: 0;
      right: 0px; */ }
      .mobile-header.menu-open .logo {
        margin-left: 30px; }
      .mobile-header.menu-open .menu {
        margin-left: auto;
        margin-right: 35px; }
    .mobile-header .logo {
      background-color: #f0eef1;
      margin-left: 22px; }
    .mobile-header .full-logo {
      margin-left: 22px; }
    .mobile-header .menu {
      background-color: #f0eef1;
      margin-left: auto;
      margin-right: 22px; }
  .yellow-menu {
    background-color: var(--main-yellow);
    height: 88px; }
    .yellow-menu .menu {
      background-color: var(--main-yellow);
      height: 8px; }
  .container::-webkit-scrollbar {
    display: none; }
  .container {
    height: 88px;
    overflow-x: auto;
    position: relative; }
    .container::before {
      content: "";
      height: auto; }
    .container.onMobileChecklist {
      background-image: none !important;
      background-color: white; }
      .container.onMobileChecklist::before {
        content: none; }
    .container .content .actions {
      width: fit-content;
      padding: 0 10px; }
      .container .content .actions .journey-btn {
        display: flex; }
        .container .content .actions .journey-btn .notification {
          font-weight: normal;
          color: #4bc4e4; }
      .container .content .actions .weather-btn {
        width: 20px;
        min-width: 0px;
        padding: 0;
        margin-right: 0; }
      .container .content .actions .auth-btn {
        margin-right: 0;
        font-weight: bold;
        text-decoration: underline; }
  .weather-container {
    width: auto;
    height: 88px; }
    .weather-container .country-selector {
      border: none; }
    .weather-container .weather-days-container .daily-container {
      padding-left: 15px; }
      .weather-container .weather-days-container .daily-container .day {
        padding-top: 10px; }
      .weather-container .weather-days-container .daily-container .temp-container {
        height: 15px;
        margin-right: 5px; }
        .weather-container .weather-days-container .daily-container .temp-container .temp {
          font-size: 12px; }
        .weather-container .weather-days-container .daily-container .temp-container .icon {
          margin-right: 5px; }
    .weather-container .close-container .close-btn svg {
      opacity: 1; } }
