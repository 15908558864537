@media screen and (min-device-width: 481px) {
  .clickable-link {
    cursor: pointer;
    opacity: 0.75;
    transition: opacity 0.25s ease;
    color: var(--main-pink); }
    .clickable-link:hover {
      opacity: 1; } }

@media screen and (max-device-width: 480px) {
  .clickable-link {
    cursor: pointer; } }
