.loader {
  display: inline-block;
  text-align: center;
  opacity: 1; }

.loader.xsmall {
  min-width: 15px; }

.loader.xsmall .wheel {
  width: 9px;
  height: 15px; }

.loader.small {
  min-width: 24px; }

.loader.small .wheel {
  width: 12px;
  height: 24px;
  margin-left: -12px; }

.loader.medium {
  min-width: 80px; }

.loader.medium .wheel {
  width: 40px;
  height: 80px;
  margin-left: -40px; }

.loader.large {
  min-width: 136px; }

.loader.large .wheel {
  width: 68px;
  height: 136px;
  margin-left: -68px; }

.wheel {
  display: inline-block;
  position: relative;
  animation: wheel-rotate-semi 1s linear infinite;
  overflow: hidden;
  transform-origin: 100% 50%; }
  .wheel::before, .wheel::after {
    position: absolute;
    top: 0;
    right: -100%;
    bottom: 0;
    left: 0;
    border-radius: 50%;
    transform: rotate(-45deg);
    animation: wheel-rotate-inner 0.5s linear infinite alternate;
    content: ''; }
  .wheel::before, .wheel::after {
    color: var(--main-purple);
    border: 5px solid var(--main-purple);
    border-color: var(--main-purple) transparent transparent var(--main-purple); }
  .wheel::after {
    opacity: 0; }

@keyframes wheel-rotate-inner {
  100% {
    opacity: 1;
    transform: rotate(115deg); } }

@keyframes wheel-rotate-semi {
  0% {
    transform: rotate(180deg); }
  45% {
    transform: rotate(198deg); }
  55% {
    transform: rotate(234deg); }
  100% {
    transform: rotate(540deg); } }
