@media screen and (min-device-width: 481px) {
  .on-homepage {
    width: fit-content !important;
    margin-right: 0 !important;
    margin-left: auto !important; }
  .menu-container {
    position: relative;
    display: flex;
    flex-direction: column; }
    .menu-container .menu-surface {
      padding: 15px 10px 15px 15px;
      width: 149px;
      display: flex;
      flex-direction: column;
      margin-top: 67px;
      border: solid 0.1px #979797; }
      .menu-container .menu-surface.hide {
        display: none; }
      .menu-container .menu-surface .menu-item-container {
        margin-bottom: 10px; }
        .menu-container .menu-surface .menu-item-container:last-child {
          margin-bottom: 0 !important; }
        .menu-container .menu-surface .menu-item-container:hover .menu-item {
          color: var(--main-pink); }
        .menu-container .menu-surface .menu-item-container:hover .divider {
          border: solid 1px rgba(248, 108, 119, 0.2); }
        .menu-container .menu-surface .menu-item-container .menu-item {
          transition: color 0.1s ease-in;
          text-align: start;
          font-size: 15px;
          font-weight: 300;
          letter-spacing: 0.31px;
          color: #454545;
          margin-bottom: 5px; }
        .menu-container .menu-surface .menu-item-container .divider {
          width: 100%;
          transition: border 0.1s ease-in;
          border: solid 1px rgba(151, 151, 151, 0.15);
          background-color: white; }
  .header-profile-container {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center; }
    .header-profile-container .header-profile {
      padding: 6px;
      color: var(--main-pink);
      border: 1.1px solid var(--main-pink); }
    .header-profile-container .user-name {
      margin-right: 5px;
      margin-left: 12.5px; }
    .header-profile-container .avatar {
      margin-left: 10px;
      width: 56px;
      height: 56px;
      border-radius: 100px;
      border: none !important; } }

@media screen and (max-device-width: 481px) {
  .header-profile-container {
    display: none; } }
