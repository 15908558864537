.auth-container {
  display: flex;
  flex-direction: column;
  width: 413px;
  height: 50vh; }
  .auth-container .auth-success {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    justify-content: space-evenly;
    padding: 40px;
    text-align: center;
    line-height: 30px;
    font-size: 18px; }
  .auth-container .auth-loader {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    justify-content: center; }
  .auth-container .auth-error {
    padding: 10px;
    color: #e26a6a !important; }
  .auth-container .auth-title {
    margin-top: 35px;
    text-align: center; }
  .auth-container .divider {
    height: 1.5px;
    background-color: grey;
    opacity: 0.2; }
  .auth-container .extrenal-auth {
    margin-top: 25px; }
  .auth-container .auth-form-body {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
    .auth-container .auth-form-body .auth-btn {
      margin-top: 32px;
      margin-bottom: 25px;
      min-width: 82px;
      height: 44px;
      opacity: 1;
      pointer-events: all;
      transition: background-color 0.4s ease;
      font-size: 13px;
      font-weight: bold;
      color: white; }
    .auth-container .auth-form-body .fade {
      transition: none;
      background-color: rgba(117, 50, 150, 0.6) !important;
      pointer-events: none;
      box-shadow: none; }
    .auth-container .auth-form-body .auth-forgot {
      transition: opacity 0.1s ease-in;
      opacity: 0.75;
      color: var(--medium-purple);
      font-size: 11px;
      letter-spacing: 0.31px;
      margin-bottom: 44px; }
      .auth-container .auth-form-body .auth-forgot:hover {
        opacity: 1; }

.auth-footer {
  margin: 8px 0;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center; }
  .auth-footer .footer-text {
    font-size: 13px;
    font-weight: 550;
    color: black;
    margin-right: 5px; }
  .auth-footer .footer-link {
    transition: opacity 0.1s ease-in;
    opacity: 0.75;
    color: var(--medium-purple);
    font-size: 13px;
    font-weight: 550; }
    .auth-footer .footer-link:hover {
      opacity: 1; }
