.item-list-ad-container {
  display: flex;
  justify-content: flex-end;
  height: 49px;
  padding-left: 11px; }

.removed {
  display: none;
  height: 0px; }

.next-question-container {
  width: 100%;
  height: 100px;
  background-image: radial-gradient(circle at 0 0, #ffffff, #f0eef1);
  display: flex;
  align-items: center;
  transition: height 0.1s ease-out; }
  .next-question-container .wrapper {
    display: flex;
    align-items: center;
    width: 100%; }
    .next-question-container .wrapper .edit {
      display: flex;
      margin-left: auto;
      margin-right: 24px; }
      .next-question-container .wrapper .edit .story-btn {
        opacity: 1;
        transition: opacity 0.45s ease-in-out;
        margin-left: auto; }
      .next-question-container .wrapper .edit .remove-opacity {
        opacity: 0;
        overflow: hidden; }

.question {
  display: flex;
  align-items: baseline;
  transition: opacity 0.45s ease-in-out;
  opacity: 1; }
  .question .input-container {
    display: flex;
    align-items: center; }
  .question.remove-opacity {
    opacity: 0;
    overflow: hidden; }
  .question .text {
    padding: 0 12px 0 24px; }
  .question-btns {
    display: flex;
    align-items: baseline; }
    .question-btns .skip-btn {
      opacity: 0.6;
      margin-left: 15px; }
    .question-btns .approve-btn {
      margin-left: 25px; }

.loading {
  pointer-events: none;
  opacity: 0.5 !important; }

.new-category {
  width: 444px;
  margin-right: 22px; }

.category-outer {
  padding-right: 22px;
  width: 444px; }

.category {
  max-height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 16px;
  background-color: #ffffff;
  box-shadow: 0 6px 6px 0 rgba(71, 15, 99, 0.05); }
  .category-title {
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    color: var(--grape-purple);
    padding: 12px 22px;
    letter-spacing: 0.5px;
    font-size: 20px;
    min-height: 50px;
    max-height: 50px; }
    .category-title:hover .category-actions {
      opacity: 1;
      display: inline-block; }
    .category-title-text {
      min-height: 25px;
      max-height: 50px;
      padding-bottom: 2px;
      vertical-align: middle;
      display: block;
      max-width: 325px;
      box-sizing: border-box;
      overflow: hidden !important;
      text-overflow: ellipsis; }
  .category .add-new-item {
    width: 100%;
    display: flex;
    position: relative;
    align-items: center;
    opacity: 0.5; }
    .category .add-new-item .blurred {
      opacity: 0.8; }
    .category .add-new-item:hover {
      transition: opacity 0.15s ease-in;
      opacity: 0.85; }
    .category .add-new-item.focused {
      opacity: 1; }
    .category .add-new-item input {
      height: 48px;
      font-size: 14px;
      letter-spacing: 0.3px;
      color: var(--main-pink);
      margin-left: 22px;
      width: calc(100% - 22px);
      padding-bottom: 10px;
      padding-top: 10px;
      background-image: linear-gradient(to right, var(--main-pink) 30%, rgba(255, 255, 255, 0) 0%);
      background-position: bottom;
      background-size: 8px 2px;
      background-repeat: repeat-x;
      font-weight: normal; }
      .category .add-new-item input::placeholder {
        color: var(--main-pink); }
    .category .add-new-item i {
      color: var(--main-pink);
      position: absolute;
      right: 0; }
  .category .new-input-btn {
    position: absolute;
    right: 0; }
  .category-actions {
    display: none;
    margin-left: auto;
    margin-right: -15px; }
    .category-actions button {
      padding: 0 !important; }
  .category-menu {
    width: 150px;
    right: 0;
    left: auto !important; }
  .category-edit-input {
    width: 100%;
    display: flex;
    align-items: center; }
    .category-edit-input input {
      width: 100%;
      background-image: none;
      color: var(--main-purple);
      font-size: 14px;
      padding: 15px 0;
      font-weight: normal; }
    .category-edit-input i {
      margin-right: -10px; }
  .category .menu-open {
    opacity: 1;
    display: inline-block; }
  .category .add-new-list {
    width: 100%;
    display: flex;
    position: relative;
    align-items: center;
    opacity: 0.5; }
    .category .add-new-list:hover {
      transition: opacity 0.15s ease-in;
      opacity: 0.85; }
    .category .add-new-list.focused {
      opacity: 1; }
    .category .add-new-list input {
      font-size: 20px;
      letter-spacing: 0.5px;
      color: var(--main-pink);
      padding: 25px;
      margin-left: 5px;
      width: 100%;
      background-image: none;
      font-weight: normal; }
      .category .add-new-list input::placeholder {
        color: var(--main-pink); }
    .category .add-new-list i {
      color: var(--main-pink);
      position: absolute;
      right: 0; }
  .category .items {
    padding-bottom: 32px;
    overflow-y: auto; }
    .category .items-disabled {
      opacity: 0.4;
      padding-top: 15px; }

.item-content {
  display: flex;
  height: 52px;
  align-items: center; }
  .item-content .item-text {
    display: inline-block;
    overflow: hidden !important;
    text-overflow: ellipsis;
    max-height: 36px;
    line-height: 18px;
    max-width: 300px;
    white-space: initial;
    vertical-align: middle; }
  .item-content .item-recently-added {
    font-style: italic; }

.item .checkbox {
  /* width: 18px;
    height: 18px; */
  border-radius: 50%; }

.item-actions {
  margin-left: auto;
  display: none; }

.item-menu {
  width: 150px;
  height: auto; }

.item-delete-checked {
  padding: 15px; }

.item-edit-input {
  width: 100%;
  display: flex;
  align-items: center; }
  .item-edit-input input {
    width: 100%;
    background-image: none;
    color: var(--main-purple);
    font-size: 14px;
    padding: 15px 0 15px 45px;
    font-weight: normal; }
  .item-edit-input i {
    margin-right: -10px; }

.item .divider {
  height: 2px;
  background: #f0eef1;
  margin-right: -8px;
  margin-left: 42px; }

.item .menu-open {
  display: inline-block; }

.item:hover .item-actions {
  opacity: 1;
  display: inline-block; }

.collapse-height {
  height: 0px;
  overflow: hidden; }

.suitcase-icon {
  margin-right: 16.9px;
  width: 22.3px;
  height: 18.6px;
  object-fit: contain;
  background-color: var(--rosy-pink); }

@media screen and (max-device-width: 480px) {
  .next-question-container {
    overflow: hidden !important; }
    .next-question-container span {
      z-index: 99; }
    .next-question-container .wrapper .edit {
      display: none; }
      .next-question-container .wrapper .edit .story-btn {
        display: none; }
  .question {
    align-items: center; }
    .question .text {
      font-size: 16px; }
    .question .input-container {
      width: 100%;
      align-items: center;
      justify-content: space-between; }
      .question .input-container span {
        width: 75%;
        height: 50px; }
    .question .approve-btn {
      display: inline-block;
      margin-top: 20px; }
      .question .approve-btn svg {
        height: 40px;
        width: 40px;
        cursor: pointer; }
  .category-outer {
    width: calc(100vw - 48px);
    padding-right: 0; }
  .new-category {
    width: 100%; }
  .category {
    height: auto;
    width: 100%; }
    .category-title {
      padding: 12px 8px 12px 22px;
      height: 70px; }
      .category-title-text {
        width: 100%; }
    .category .add-new-item input {
      padding-top: 15px; }
    .category-actions {
      opacity: 1;
      display: block;
      margin-right: 0; }
    .category .items {
      margin-bottom: 20px;
      height: auto; }
  .item-content .item-text {
    width: 100%; }
  .item .checkbox label {
    font-weight: normal !important; }
  .item-actions {
    opacity: 1;
    display: block; } }
