@media screen and (min-device-width: 481px) {
  .floating-btn-container {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 66px;
    width: 66px;
    height: 66px;
    left: 32px;
    box-shadow: 0 6px 6px 0 rgba(71, 15, 99, 0.05), 0 2px 0 0 rgba(240, 238, 241, 0.24);
    background-color: #ffffff;
    position: fixed;
    bottom: 97px;
    box-shadow: 0 0 11px rgba(0, 0, 0, 0.2);
    z-index: 1; }
    .floating-btn-container.is-homepage {
      position: fixed;
      bottom: 31px; }
    .floating-btn-container.right {
      left: auto;
      right: 32px; }
  .print-icon {
    width: 35px;
    height: 35px;
    object-fit: contain; }
  .share-icon {
    width: 21px;
    height: 23px;
    object-fit: contain; }
  .dots-icon {
    width: 33px;
    height: 20px;
    object-fit: contain; } }
