.form-col {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 12px;
  min-height: 40px; }
  .form-col div input {
    max-width: 200px; }
  .form-col input {
    font-size: 20px;
    letter-spacing: 0.5px; }

.error .dropdown-input, .error input {
  color: #F44336 !important;
  background-image: linear-gradient(to right, #F44336 50%, rgba(255, 255, 255, 0) 0%) !important; }
  .error .dropdown-input::placeholder, .error input::placeholder {
    color: #F44336 !important; }

.alt-handle {
  border-bottom: solid 1px rgba(151, 151, 151, 0.5);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 17px;
  font-weight: 500;
  color: #515152;
  padding: 0 5px 8px 5px; }
  .alt-handle svg {
    margin-left: auto;
    height: 15px;
    width: 15px; }
  .alt-handle.days svg {
    transform: rotate(180deg); }

.text-after {
  font-size: 20px !important;
  padding-left: 14px;
  letter-spacing: 0.36px;
  color: #753296; }

.text-before {
  font-size: 20px !important;
  padding-right: 14px;
  letter-spacing: 0.36px;
  color: #753296; }

.dropdown-input {
  padding: 0 35px 0 0 !important;
  font-size: 20px !important; }

.line-breaker {
  width: 100%; }

.errors-container {
  padding: 5px 3px;
  width: 35vw;
  min-width: 350px;
  border-radius: 3px;
  color: white;
  background-color: var(--error-box); }
  .errors-container.timeline {
    margin: auto; }
  .errors-container .error {
    padding: 3.5px;
    width: 100%; }

.errors-auth {
  min-width: 0px;
  max-width: 75%;
  padding: 0;
  margin-top: 0;
  margin-bottom: 10px; }
  .errors-auth .error {
    max-width: 75%;
    margin-left: 5px;
    padding: 5px;
    width: 75%; }

.input-from-to {
  display: flex;
  align-items: baseline; }

.input-field-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 75%;
  margin-bottom: 12px; }
  .input-field-container .input-field {
    width: 100% !important;
    color: black !important;
    font-size: 14px !important;
    border-bottom: 0.0625rem solid #b2b2bf;
    height: 35px;
    text-decoration: none !important;
    background-image: none !important;
    padding-left: 8px; }
    .input-field-container .input-field::placeholder {
      vertical-align: middle;
      opacity: 0.75;
      color: #b2b2bf;
      font-size: 15px;
      font-weight: 300;
      margin-top: auto !important;
      margin-bottom: auto !important; }
  .input-field-container .input-name {
    margin-bottom: 2px;
    height: 12px;
    font-weight: 550;
    font-size: 12px;
    color: black; }

.boolean-field-container {
  display: flex;
  justify-content: center;
  align-items: center; }
  .boolean-field-container-option {
    padding: 0 15px;
    font-size: 26px;
    line-height: 26px; }
    .boolean-field-container-option:hover, .boolean-field-container-option.selected {
      text-decoration: underline; }
    .boolean-field-container-option:not(:last-child):after {
      content: '';
      position: absolute;
      width: 2px;
      height: 30px;
      background: #d5d5d5;
      transform: rotate(345deg);
      opacity: 1;
      margin: 0 15px; }

.input-error input {
  border-bottom: 1px solid #F44336; }

.input-error-message {
  font-size: 12px;
  padding: 3px;
  color: #F44336; }

@media screen and (max-device-width: 480px) {
  .form-col {
    max-width: 350px;
    margin-bottom: 5px;
    min-height: 45px; }
    .form-col select::-ms-expand {
      display: none; }
    .form-col span {
      font-size: 20px;
      margin-right: 5px; }
      .form-col span.select-container {
        height: 50px;
        background-image: url("/static/images/dropdown-icn.svg");
        background-position: center right 8px;
        background-color: transparent;
        background-repeat: no-repeat; }
      .form-col span select {
        font-size: 20px;
        height: 45px !important;
        -moz-appearance: none !important;
        -webkit-appearance: none !important;
        background-image: linear-gradient(to right, var(--mdc-theme-primary) 50%, rgba(255, 255, 255, 0) 0%);
        background-position: bottom;
        background-size: 8px 2px;
        background-repeat: repeat-x;
        background-color: transparent;
        border: none;
        padding-right: 30px;
        padding-top: 12px;
        padding-bottom: 7.5px;
        height: 100%;
        border-radius: 0;
        font-weight: 700;
        letter-spacing: 0.5px;
        line-height: 1.25;
        vertical-align: baseline !important;
        box-sizing: border-box;
        color: var(--mdc-theme-primary); }
      .form-col span span {
        font-size: 12px !important; }
    .form-col input {
      min-height: 45px; }
    .form-col .date-container {
      width: 200px; }
    .form-col input[type="date"] {
      position: relative;
      width: 200px; }
      .form-col input[type="date"].full:before {
        content: " " !important;
        background-image: url("/static/images/close-calander-mobile.svg");
        background-color: transparent;
        background-repeat: no-repeat;
        background-position: center right 15px; }
      .form-col input[type="date"].full:focus:before {
        content: " " !important;
        background-image: url("/static/images/close-calander-mobile.svg");
        background-color: transparent;
        background-repeat: no-repeat;
        background-position: center right 15px; }
    .form-col input[type="date"]:before {
      position: absolute;
      width: 200px;
      content: attr(placeholder) !important;
      background-image: url("/static/images/cal-icn.svg");
      background-color: transparent;
      background-repeat: no-repeat;
      background-position: center right 15px; }
    .form-col input[type="date"]:focus:before {
      content: attr(placeholder) !important; }
  .line-breaker {
    width: auto; }
  .errors-container {
    width: 100%;
    margin-top: 25px; }
  .input-field-container .input-field::placeholder {
    font-size: 14px; }
  .boolean-field-container-option {
    font-size: 16px;
    line-height: 16px; } }
