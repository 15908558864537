@media screen and (max-device-width: 480px) {
  .form-col {
    max-width: 350px;
    margin-bottom: 5px;
    min-height: 45px; }
    .form-col select::-ms-expand {
      display: none; }
    .form-col span {
      font-size: 20px;
      margin-right: 5px; }
      .form-col span.select-container {
        height: 50px;
        background-image: url("/static/images/dropdown-icn.svg");
        background-position: center right 8px;
        background-color: transparent;
        background-repeat: no-repeat; }
      .form-col span select {
        font-size: 20px;
        height: 45px !important;
        -moz-appearance: none !important;
        -webkit-appearance: none !important;
        background-image: linear-gradient(to right, var(--mdc-theme-primary) 50%, rgba(255, 255, 255, 0) 0%);
        background-position: bottom;
        background-size: 8px 2px;
        background-repeat: repeat-x;
        background-color: transparent;
        border: none;
        padding-right: 30px;
        padding-top: 12px;
        padding-bottom: 7.5px;
        height: 100%;
        border-radius: 0;
        font-weight: 700;
        letter-spacing: 0.5px;
        line-height: 1.25;
        vertical-align: baseline !important;
        box-sizing: border-box;
        color: var(--mdc-theme-primary); }
      .form-col span span {
        font-size: 12px !important; }
    .form-col input {
      min-height: 45px; }
    .form-col .date-container {
      width: 200px; }
    .form-col input[type="date"] {
      position: relative;
      width: 200px; }
      .form-col input[type="date"].full:before {
        content: " " !important;
        background-image: url("/static/images/close-calander-mobile.svg");
        background-color: transparent;
        background-repeat: no-repeat;
        background-position: center right 15px; }
      .form-col input[type="date"].full:focus:before {
        content: " " !important;
        background-image: url("/static/images/close-calander-mobile.svg");
        background-color: transparent;
        background-repeat: no-repeat;
        background-position: center right 15px; }
    .form-col input[type="date"]:before {
      position: absolute;
      width: 200px;
      content: attr(placeholder) !important;
      background-image: url("/static/images/cal-icn.svg");
      background-color: transparent;
      background-repeat: no-repeat;
      background-position: center right 15px; }
    .form-col input[type="date"]:focus:before {
      content: attr(placeholder) !important; }
  .line-breaker {
    width: auto; }
  .errors-container {
    width: 100%;
    margin-top: 25px; }
  .input-field-container .input-field::placeholder {
    font-size: 14px; }
  .boolean-field-container-option {
    font-size: 16px;
    line-height: 16px; } }
