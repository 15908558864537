/*     html {
        overflow-y: hidden;
        &.on-scroll{
            overflow-y: overlay;
        }
    }
      html.on-scroll::-webkit-scrollbar-track {
        -webkit-box-shadow: none ;
        background-color: transparent ;
      }
      html.on-scroll::-webkit-scrollbar {
        width: 3px ;
        background-color: transparent;
      }
      html.on-scroll::-webkit-scrollbar-thumb {
        background-color: var(--main-purple)!important;
      } */
.container {
  margin: 0 -8px;
  padding: 0 !important;
  width: auto;
  height: auto;
  min-height: calc(100vh - 175px); }
  .container .hero-container {
    position: relative;
    display: flex;
    height: 100%;
    height: 330px;
    background-size: cover; }
    .container .hero-container-overlay {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: black;
      opacity: 0.4; }
    .container .hero-container .edit-btn {
      z-index: 1;
      transition: color 0.15s ease-in-out;
      position: absolute;
      top: 23px;
      right: 23px;
      font-size: 12px;
      letter-spacing: 0.92px;
      color: #d2d2d2; }
      .container .hero-container .edit-btn:hover {
        color: var(--main-pink); }
    .container .hero-container .hero-image {
      position: relative;
      width: 100%;
      height: auto;
      z-index: 0; }
    .container .hero-container .profile-details-container {
      position: absolute;
      margin: auto 80px;
      display: flex;
      z-index: 1;
      height: 100%;
      align-items: center; }
      .container .hero-container .profile-details-container .profile-image {
        display: flex;
        align-items: center;
        flex-direction: column;
        position: relative;
        border-radius: 256px;
        margin-right: 45px; }
        .container .hero-container .profile-details-container .profile-image:hover {
          cursor: pointer; }
          .container .hero-container .profile-details-container .profile-image:hover .edit {
            background-color: var(--main-pink); }
        .container .hero-container .profile-details-container .profile-image .image {
          width: 200px;
          height: 200px;
          border-radius: 256px; }
        .container .hero-container .profile-details-container .profile-image .edit {
          transition: background-color 0.25s ease-in;
          z-index: 1;
          width: 55px;
          font-size: 11px;
          border-radius: 256px;
          color: white;
          text-align: center;
          position: absolute;
          bottom: 5px;
          background-color: #d8d8d8; }
      .container .hero-container .profile-details-container .user-details {
        display: flex;
        flex-direction: column; }
        .container .hero-container .profile-details-container .user-details .username {
          font-size: 26px;
          color: white;
          letter-spacing: 1.85px;
          font-weight: bold;
          margin-bottom: 10px; }
        .container .hero-container .profile-details-container .user-details .location-container {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          margin-bottom: 25px; }
          .container .hero-container .profile-details-container .user-details .location-container .icon {
            margin-left: 10px;
            width: 10px;
            height: 10px; }
          .container .hero-container .profile-details-container .user-details .location-container .text {
            color: white;
            font-size: 16px;
            font-weight: 300; }
        .container .hero-container .profile-details-container .user-details .mobile-number {
          color: white;
          font-size: 16px;
          font-weight: 550;
          margin-bottom: 15px; }
        .container .hero-container .profile-details-container .user-details .email {
          color: white;
          font-size: 16px;
          font-weight: 550; }
  .container .journeys-container {
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    padding: 80px; }
    .container .journeys-container .section-divider {
      width: 100%;
      height: 1px;
      background-color: #979797;
      opacity: 0.4;
      margin-top: 52px;
      margin-bottom: 63px; }

@media screen and (max-device-width: 480px) {
  .container .hero-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px; }
    .container .hero-container .hero-image {
      position: relative;
      width: auto;
      height: 199px; }
    .container .hero-container .profile-image {
      display: flex;
      align-items: center;
      flex-direction: column;
      position: relative;
      border-radius: 256px;
      margin: -60px auto 0 auto; }
      .container .hero-container .profile-image .image {
        width: 120px;
        height: 120px;
        border-radius: 256px; }
      .container .hero-container .profile-image .edit {
        transition: background-color 0.25s ease-in;
        z-index: 1;
        width: 55px;
        font-size: 11px;
        border-radius: 256px;
        color: white;
        text-align: center;
        position: absolute;
        bottom: 5px;
        background-color: #d8d8d8; }
    .container .hero-container .profile-details-container {
      position: relative;
      margin: auto; }
      .container .hero-container .profile-details-container .user-details {
        align-items: center; }
        .container .hero-container .profile-details-container .user-details .location-container .text {
          color: var(--grape-purple); }
        .container .hero-container .profile-details-container .user-details .username {
          font-size: 24px; }
        .container .hero-container .profile-details-container .user-details .mobile-number {
          font-weight: 300;
          line-height: 1.88;
          margin: 0; }
        .container .hero-container .profile-details-container .user-details .email {
          font-weight: 300;
          line-height: 1.88; }
  .container .journeys-container {
    padding: 0 18px; } }
