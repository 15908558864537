:global {
  /* dropdown menu */
  /** Overrides for icons */
  /* DayPicker styles */
  /* Default modifiers */
  /* Example modifiers */
  /* Algolia */
  /* DayPickerInput */ }
  :global :root {
    --rosy-pink: #f86c77;
    --main-blue: #2ab8de;
    --main-purple: #753296;
    --medium-purple: #884ea4;
    --grape-purple: #470f63;
    --main-yellow: #f7c258;
    --main-pink: #f86c77;
    --error-box: #d1435b;
    --gray-border: #979797;
    --journeys-background: rgba(39,39,39, 0.53);
    --black-60: rgba(0,0,0, 0.6);
    --mdc-theme-primary: var(--main-blue);
    --mdc-theme-primary-light: var(--main-pink);
    --mdc-theme-primary-dark: #8936ff;
    --mdc-theme-secondary: var(--main-purple);
    --mdc-theme-secondary-light: var(--main-yellow);
    --mdc-theme-secondary-dark: #004040;
    --mdc-theme-background: #fff;
    --mdc-theme-text-primary-on-primary: #fff;
    --mdc-theme-text-primary-on-primary-dark: #fff;
    --mdc-theme-text-primary-on-secondary: #fff;
    --mdc-theme-text-primary-on-secondary-dark: #fff;
    --mdc-theme-text-primary-on-dark: #fff;
    --mdc-theme-surface: #ffffff;
    --mdc-theme-on-surface: var(--grape-purple); }
  :global html {
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    color: var(--main-purple);
    letter-spacing: 0.6px;
    white-space: pre-wrap; }
  :global :focus {
    outline: none; }
  :global .mdc-typography--modalheader {
    font-size: 24px;
    letter-spacing: 0.23px; }
  :global .mdc-typography--body1 {
    font-size: 26px;
    line-height: 26px;
    font-family: "Open Sans", sans-serif;
    color: var(--main-purple); }
  :global .mdc-typography--body2 {
    font-size: 14px;
    font-family: "Open Sans", sans-serif;
    color: var(--main-purple); }
  :global .mdc-typography--body3 {
    font-size: 14px;
    font-family: "Open Sans", sans-serif;
    color: var(--main-pink);
    letter-spacing: 0.3px; }
  :global .mdc-typography--body4 {
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
    font-family: "Open Sans", sans-serif;
    color: var(--black-60);
    letter-spacing: 0.3px; }
  :global .mdc-typography--body5 {
    font-size: 16px;
    font-family: "Open Sans", sans-serif;
    color: #515152; }
  :global .mdc-typography--journeyTitle {
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0.69px;
    color: white; }
  :global .mdc-typography--journeyTitle2 {
    font-size: 30px;
    font-weight: 600;
    letter-spacing: 0.81px;
    color: white; }
  :global .mdc-typography--journeySub1 {
    font-size: 18px;
    letter-spacing: 0.51px;
    color: white; }
  :global .mdc-typography--journeySub1Mobile {
    font-size: 16px;
    letter-spacing: 0.46px;
    color: white; }
  :global .mdc-typography--journeySub2 {
    font-size: 16px;
    font-weight: 600;
    line-height: 1.06;
    letter-spacing: 0.81px;
    color: #f0eef1; }
  :global .mdc-typography--journeySub3 {
    font-size: 14px;
    line-height: 1.21;
    letter-spacing: 0.81px;
    color: #f0eef1; }
  :global .mdc-typography--journeySub4 {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.81px;
    color: white; }
  :global .mdc-typography--journeySub5 {
    font-size: 24px;
    letter-spacing: 0.81px;
    color: #454545; }
  :global .mdc-button {
    border-radius: 50px;
    text-transform: none;
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    letter-spacing: 1px; }
  :global .mdc-button--raised {
    font-size: 13px;
    padding: 0 21px;
    height: 44px;
    box-shadow: 0 3px 3px 0 rgba(15, 71, 99, 0.1), 0 1px 0 0 #1da5c9; }
  :global .mdc-theme--secondary-bg.mdc-button--raised {
    font-size: 13px;
    padding: 0 21px;
    height: 44px;
    box-shadow: 0 6px 6px 0 rgba(15, 71, 99, 0.1), 0 2px 0 0 #6b228f; }
  :global .mdc-theme--secondary-light-bg.mdc-button--raised {
    font-size: 13px;
    padding: 0 21px;
    height: 44px;
    box-shadow: 0 6px 6px 0 rgba(99, 86, 15, 0.1), 0 2px 0 0 #de9910; }
  :global .mdc-theme--secondary-light-bg {
    background-color: var(--mdc-theme-secondary-light) !important; }
  :global .mdc-theme--primary-light-bg.mdc-button--raised {
    font-size: 13px;
    padding: 0 21px;
    height: 44px;
    box-shadow: 0 6px 6px 0 rgba(99, 86, 15, 0.1), 0 2px 0 0 var(--main-pink); }
  :global .mdc-button:disabled {
    /* background-color: #9e5ac3!important; */
    opacity: 0.6;
    color: #ffffff !important; }
  :global .mdc-theme--primary-light-bg {
    background-color: var(--main-pink) !important; }
  :global .mdc-menu-surface--anchor {
    display: inline-block; }
  :global .mdc-select,
  :global .mdc-select:not(.mdc-select--disabled) {
    background-image: url("/static/images/dropdown-icn.svg");
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center right 8px;
    height: 28px; }
    :global .mdc-select .mdc-select__native-control,
    :global .mdc-select:not(.mdc-select--disabled) .mdc-select__native-control {
      padding-left: 0;
      padding-top: 12px;
      padding-right: 35px;
      border-bottom: none;
      background-image: linear-gradient(to right, var(--mdc-theme-primary) 50%, rgba(255, 255, 255, 0) 0%);
      background-position: bottom;
      background-size: 8px 2px;
      background-repeat: repeat-x;
      height: auto;
      /* text */
      font-family: "Open Sans", sans-serif;
      color: var(--mdc-theme-primary);
      font-size: 26px;
      font-weight: 700;
      letter-spacing: 0.5px;
      width: 100%; }
  :global .mdc-text-field,
  :global .mdc-text-field:not(.mdc-text-field--disabled) {
    font-family: Roboto, sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-size: 1rem;
    line-height: 1.75rem;
    font-weight: 400;
    letter-spacing: .00937em;
    text-decoration: inherit;
    text-transform: inherit;
    align-self: flex-end;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 20px 12px 6px;
    transition: opacity 180ms cubic-bezier(0.4, 0, 0.2, 1);
    border: none;
    border-bottom: 1px solid;
    border-radius: 0;
    background: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: transparent;
    padding-left: 0;
    padding-top: 12px;
    border-bottom: none;
    background-image: linear-gradient(to right, var(--mdc-theme-primary) 50%, rgba(255, 255, 255, 0) 0%);
    background-position: bottom;
    background-size: 8px 2px;
    background-repeat: repeat-x;
    height: auto;
    /* text */
    font-family: "Open Sans", sans-serif;
    color: var(--mdc-theme-primary);
    font-size: 26px;
    font-weight: 700;
    letter-spacing: 0.5px; }
  :global .mdc-tab {
    height: 62px;
    min-width: 320px;
    text-align: left;
    padding: 0px 32px;
    background-color: #ffffff;
    color: var(--main-pink);
    text-transform: none;
    font-size: 14px;
    font-family: "Open Sans", sans-serif;
    letter-spacing: 0.3px;
    border-right: solid 4px #f0eef1;
    align-items: center; }
  :global .mdc-tab--active,
  :global .mdc-tab:hover,
  :global .mdc-tab__ripple::before,
  :global .mdc-tab__ripple::after {
    background-color: var(--mdc-theme-primary, #6200ee);
    background-color: #f0eef1;
    color: var(--main-purple); }
  :global .mdc-tab-bar__indicator {
    background-color: transparent; }
  :global .mdc-checkbox__background {
    border-radius: 50%;
    box-shadow: 0 2px 0 0 rgba(255, 255, 255, 0.4);
    border: solid 2px rgba(117, 50, 150, 0.6); }
  :global .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate) ~ .mdc-checkbox__background {
    border-color: rgba(117, 50, 150, 0.6); }
  :global .multiselect-option .mdc-form-field {
    display: flex;
    padding: 0 0 0 5px;
    color: rgba(0, 0, 0, 0.87); }
  :global .multiselect-option .mdc-checkbox__background {
    border-radius: 2px !important;
    background-color: white !important;
    border-color: var(--mdc-checkbox-unchecked-color, rgba(0, 0, 0, 0.54)) !important; }
    :global .multiselect-option .mdc-checkbox__background .mdc-checkbox__checkmark {
      color: var(--mdc-checkbox-unchecked-color, rgba(0, 0, 0, 0.54)) !important; }
  :global .mdc-form-field {
    font-size: 14px;
    color: var(--main-purple);
    letter-spacing: 0.3px;
    cursor: pointer; }
    :global .mdc-form-field label {
      cursor: pointer;
      flex: 1; }
  :global .mdc---open,
  :global .mdc-dialog--opening,
  :global .mdc-dialog--closing {
    background-color: rgba(0, 0, 0, 0.32); }
  :global .mdc-dialog__surface {
    padding: 0;
    max-width: 100vw !important; }
  :global .rmwc-circular-progress {
    font-size: 1.5rem;
    position: relative;
    display: inline-block;
    width: 1em;
    height: 1em;
    -webkit-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
    color: var(--mdc-theme-primary, #6200ee); }
  :global .rmwc-circular-progress--size-xsmall {
    font-size: 1.125rem; }
  :global .rmwc-circular-progress--size-small {
    font-size: 1.25rem; }
  :global .rmwc-circular-progress--size-medium {
    font-size: 1.5rem; }
  :global .rmwc-circular-progress--size-large {
    font-size: 2.25rem; }
  :global .rmwc-circular-progress--size-xlarge {
    font-size: 3rem; }
  :global .rmwc-circular-progress--indeterminate .rmwc-circular-progress__circle {
    -webkit-animation: rmwc-circular-progress-indeterminate-bar-rotate 2s linear infinite;
    animation: rmwc-circular-progress-indeterminate-bar-rotate 2s linear infinite; }
  :global .rmwc-circular-progress--indeterminate .rmwc-circular-progress__path {
    -webkit-animation: rmwc-circular-progress-indeterminate-bar-dash 1.5s ease-in-out infinite;
    animation: rmwc-circular-progress-indeterminate-bar-dash 1.5s ease-in-out infinite;
    stroke-dasharray: 2.08%, 416%;
    stroke-dashoffset: 0; }
  :global .rmwc-circular-progress__circle {
    height: 100%;
    width: 100%; }
  :global .rmwc-circular-progress__path {
    -webkit-transition: stroke-dasharray 0.35s cubic-bezier(0.4, 0, 0.2, 1);
    -o-transition: stroke-dasharray 0.35s cubic-bezier(0.4, 0, 0.2, 1);
    transition: stroke-dasharray 0.35s cubic-bezier(0.4, 0, 0.2, 1);
    fill: none;
    stroke-dasharray: 0, 416%;
    stroke-dashoffset: 0;
    stroke-linecap: round;
    stroke-miterlimit: 20;
    stroke-width: 0.125rem;
    stroke: currentColor; }
  :global .rmwc-circular-progress--thickerstroke .rmwc-circular-progress__path {
    stroke-width: 0.25rem; }
  :global .rmwc-icon .rmwc-circular-progress {
    font-size: inherit; }

@-webkit-keyframes rmwc-circular-progress-indeterminate-bar-rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes rmwc-circular-progress-indeterminate-bar-rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@-webkit-keyframes rmwc-circular-progress-indeterminate-bar-dash {
  0% {
    stroke-dasharray: 2.08%, 416%;
    stroke-dashoffset: 0%; }
  50% {
    stroke-dasharray: 185.4%, 416%;
    stroke-dashoffset: -72.9%; }
  to {
    stroke-dasharray: 185.4%, 416%;
    stroke-dashoffset: -258.33%; } }

@keyframes rmwc-circular-progress-indeterminate-bar-dash {
  0% {
    stroke-dasharray: 2.08%, 416%;
    stroke-dashoffset: 0%; }
  50% {
    stroke-dasharray: 185.4%, 416%;
    stroke-dashoffset: -72.9%; }
  to {
    stroke-dasharray: 185.4%, 416%;
    stroke-dashoffset: -258.33%; } }

@keyframes rmwcToolTipIn {
  0% {
    opacity: 0;
    transform-origin: 50% 50%;
    transform: scale(0, 0); }
  100% {
    opacity: 1;
    transform-origin: 50% 50%;
    transform: scale(1, 1); } }

@keyframes rmwcToolTipOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }
  :global .rmwc-tooltip {
    position: absolute;
    z-index: 9999;
    display: block;
    visibility: visible;
    line-height: 1.5;
    font-size: 0.75rem;
    font-family: inherit;
    padding: 1px;
    opacity: 0.9; }
  :global .rmwc-tooltip-hidden {
    display: none; }
  :global .rmwc-tooltip.rmwc-tooltip-zoom-enter,
  :global .rmwc-tooltip.rmwc-tooltip-zoom-leave {
    display: block; }
  :global .rmwc-tooltip-zoom-enter,
  :global .rmwc-tooltip-zoom-appear {
    opacity: 0;
    animation-duration: 0.15s;
    animation-fill-mode: both;
    animation-play-state: paused; }
  :global .rmwc-tooltip-zoom-leave {
    animation-duration: 0.075s;
    animation-fill-mode: both;
    animation-play-state: paused; }
  :global .rmwc-tooltip-zoom-enter.rmwc-tooltip-zoom-enter-active,
  :global .rmwc-tooltip-zoom-appear.rmwc-tooltip-zoom-appear-active {
    animation-name: rmwcToolTipIn;
    animation-play-state: running; }
  :global .rmwc-tooltip-zoom-leave.rmwc-tooltip-zoom-leave-active {
    animation-name: rmwcToolTipOut;
    animation-play-state: running; }
  :global .rmwc-tooltip-inner {
    display: flex;
    align-items: center;
    padding: 0.33rem 1rem;
    color: var(--mdc-theme-surface, white);
    text-align: left;
    text-decoration: none;
    background-color: var(--mdc-theme-on-surface, black);
    border-radius: 0.25rem;
    min-height: 2rem;
    box-sizing: border-box; }
  :global .rmwc-tooltip--show-arrow .rmwc-tooltip-arrow {
    display: block; }
  :global .rmwc-tooltip-arrow {
    display: none; }
  :global .rmwc-tooltip-arrow,
  :global .rmwc-tooltip-arrow-inner {
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid; }
  :global .rmwc-tooltip-placement-top .rmwc-tooltip-arrow,
  :global .rmwc-tooltip-placement-topLeft .rmwc-tooltip-arrow,
  :global .rmwc-tooltip-placement-topRight .rmwc-tooltip-arrow {
    bottom: -5px;
    margin-left: -6px;
    border-width: 6px 6px 0;
    border-top-color: var(--mdc-theme-on-surface, black); }
  :global .rmwc-tooltip-placement-top .rmwc-tooltip-arrow-inner,
  :global .rmwc-tooltip-placement-topLeft .rmwc-tooltip-arrow-inner,
  :global .rmwc-tooltip-placement-topRight .rmwc-tooltip-arrow-inner {
    bottom: 1px;
    margin-left: -6px;
    border-width: 6px 6px 0;
    border-top-color: var(--mdc-theme-surface, white); }
  :global .rmwc-tooltip-placement-top .rmwc-tooltip-arrow {
    left: 50%; }
  :global .rmwc-tooltip-placement-topLeft .rmwc-tooltip-arrow {
    left: 15%; }
  :global .rmwc-tooltip-placement-topRight .rmwc-tooltip-arrow {
    right: 15%; }
  :global .rmwc-tooltip-placement-right .rmwc-tooltip-arrow,
  :global .rmwc-tooltip-placement-rightTop .rmwc-tooltip-arrow,
  :global .rmwc-tooltip-placement-rightBottom .rmwc-tooltip-arrow {
    left: -5px;
    margin-top: -6px;
    border-width: 6px 6px 6px 0;
    border-right-color: var(--mdc-theme-on-surface, black); }
  :global .rmwc-tooltip-placement-right .rmwc-tooltip-arrow-inner,
  :global .rmwc-tooltip-placement-rightTop .rmwc-tooltip-arrow-inner,
  :global .rmwc-tooltip-placement-rightBottom .rmwc-tooltip-arrow-inner {
    left: 1px;
    margin-top: -6px;
    border-width: 6px 6px 6px 0;
    border-right-color: var(--mdc-theme-surface, white); }
  :global .rmwc-tooltip-placement-right .rmwc-tooltip-arrow {
    top: 50%; }
  :global .rmwc-tooltip-placement-rightTop .rmwc-tooltip-arrow {
    top: 15%;
    margin-top: 0; }
  :global .rmwc-tooltip-placement-rightBottom .rmwc-tooltip-arrow {
    bottom: 15%; }
  :global .rmwc-tooltip-placement-left .rmwc-tooltip-arrow,
  :global .rmwc-tooltip-placement-leftTop .rmwc-tooltip-arrow,
  :global .rmwc-tooltip-placement-leftBottom .rmwc-tooltip-arrow {
    right: -5px;
    margin-top: -6px;
    border-width: 6px 0 6px 6px;
    border-left-color: var(--mdc-theme-on-surface, black); }
  :global .rmwc-tooltip-placement-left .rmwc-tooltip-arrow-inner,
  :global .rmwc-tooltip-placement-leftTop .rmwc-tooltip-arrow-inner,
  :global .rmwc-tooltip-placement-leftBottom .rmwc-tooltip-arrow-inner {
    right: 1px;
    margin-top: -6px;
    border-width: 6px 0 6px 6px;
    border-left-color: var(--mdc-theme-surface, white); }
  :global .rmwc-tooltip-placement-left .rmwc-tooltip-arrow {
    top: 50%; }
  :global .rmwc-tooltip-placement-leftTop .rmwc-tooltip-arrow {
    top: 15%;
    margin-top: 0; }
  :global .rmwc-tooltip-placement-leftBottom .rmwc-tooltip-arrow {
    bottom: 15%; }
  :global .rmwc-tooltip-placement-bottom .rmwc-tooltip-arrow,
  :global .rmwc-tooltip-placement-bottomLeft .rmwc-tooltip-arrow,
  :global .rmwc-tooltip-placement-bottomRight .rmwc-tooltip-arrow {
    top: -5px;
    margin-left: -6px;
    border-width: 0 6px 6px;
    border-bottom-color: var(--mdc-theme-on-surface, black); }
  :global .rmwc-tooltip-placement-bottom .rmwc-tooltip-arrow-inner,
  :global .rmwc-tooltip-placement-bottomLeft .rmwc-tooltip-arrow-inner,
  :global .rmwc-tooltip-placement-bottomRight .rmwc-tooltip-arrow-inner {
    top: 1px;
    margin-left: -6px;
    border-width: 0 6px 6px;
    border-bottom-color: var(--mdc-theme-surface, white); }
  :global .rmwc-tooltip-placement-bottom .rmwc-tooltip-arrow {
    left: 50%; }
  :global .rmwc-tooltip-placement-bottomLeft .rmwc-tooltip-arrow {
    left: 15%; }
  :global .rmwc-tooltip-placement-bottomRight .rmwc-tooltip-arrow {
    right: 15%; }
  @media (min-width: 768px) {
    :global .rmwc-tooltip-inner {
      padding: 0.33rem 0.5rem;
      min-height: 1.5rem; } }
  :global .mdc-snackbar__surface {
    background-color: var(--main-purple); }
  :global .mdc-snackbar__action:not(:disabled) {
    color: var(--main-yellow); }
  @media (min-width: 768px) {
    :global .mdc-snackbar {
      bottom: 90px; } }
  :global .DayPicker {
    display: inline-block;
    font-size: 1rem; }
  :global .DayPicker-wrapper {
    position: relative;
    flex-direction: row;
    padding-bottom: 1em;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
  :global .DayPicker-Months {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; }
  :global .DayPicker-Month {
    display: table;
    margin: 0 1em;
    margin-top: 1em;
    border-spacing: 0;
    border-collapse: collapse;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
  :global .DayPicker-NavButton {
    position: absolute;
    top: 1em;
    right: 1.5em;
    left: auto;
    display: inline-block;
    margin-top: 2px;
    width: 1.25em;
    height: 1.25em;
    background-position: center;
    background-size: 50%;
    background-repeat: no-repeat;
    color: #8B9898;
    cursor: pointer; }
  :global .DayPicker-NavButton:hover {
    opacity: 0.8; }
  :global .DayPicker-NavButton--prev {
    margin-right: 1.5em;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAVVJREFUWAnN2G0KgjAYwPHpGfRkaZeqvgQaK+hY3SUHrk1YzNLay/OiEFp92I+/Mp2F2Mh2lLISWnflFjzH263RQjzMZ19wgs73ez0o1WmtW+dgA01VxrE3p6l2GLsnBy1VYQOtVSEH/atCCgqpQgKKqYIOiq2CBkqtggLKqQIKgqgCBjpJ2Y5CdJ+zrT9A7HHSTA1dxUdHgzCqJIEwq0SDsKsEg6iqBIEoq/wEcVRZBXFV+QJxV5mBtlDFB5VjYTaGZ2sf4R9PM7U9ZU+lLuaetPP/5Die3ToO1+u+MKtHs06qODB2zBnI/jBd4MPQm1VkY79Tb18gB+C62FdBFsZR6yeIo1YQiLJWMIiqVjQIu1YSCLNWFgijVjYIuhYYCKoWKAiiFgoopxYaKLUWOii2FgkophYp6F3r42W5A9s9OcgNvva8xQaysKXlFytoqdYmQH6tF3toSUo0INq9AAAAAElFTkSuQmCC"); }
  :global .DayPicker-NavButton--next {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAXRJREFUWAnN119ugjAcwPHWzJ1gnmxzB/BBE0n24m4xfNkTaOL7wOtsl3AXMMb+Vjaa1BG00N8fSEibPpAP3xAKKs2yjzTPH9RAjhEo9WzPr/Vm8zgE0+gXATAxxuxtqeJ9t5tIwv5AtQAApsfT6TPdbp+kUBcgVwvO51KqVhMkXKsVJFXrOkigVhCIs1Y4iKlWZxB1rX4gwlpRIIpa8SDkWmggrFq4IIRaJKCYWnSgnrXIQV1r8YD+1Vrn+bReagysIFfLABRt31v8oBu1xEBttfRbltmfjgEcWh9snUS2kNdBK6WN1vrOWxObWsz+fjxevsxmB1GQDfINWiev83nhaoiB/CoOU438oPrhXS0WpQ9xc1ZQWxWHqUYe0I0qrKCQKjygDlXIQV2r0IF6ViEBxVTBBSFUQQNhVYkHIVeJAtkNsbQ7c1LtzP6FsObhb2rCKv7NBIGoq4SDmKoEgTirXAcJVGkFSVVpgoSrXICGUMUH/QBZNSUy5XWUhwAAAABJRU5ErkJggg=="); }
  :global .DayPicker-NavButton--interactionDisabled {
    display: none; }
  :global .DayPicker-Caption {
    display: table-caption;
    margin-bottom: 0.5em;
    padding: 0 0.5em;
    text-align: left; }
  :global .DayPicker-Caption > div {
    font-weight: 500;
    font-size: 1.15em; }
  :global .DayPicker-Weekdays {
    display: table-header-group;
    margin-top: 1em; }
  :global .DayPicker-WeekdaysRow {
    display: table-row; }
  :global .DayPicker-Weekday {
    display: table-cell;
    padding: 0.5em;
    color: #8B9898;
    text-align: center;
    font-size: 0.875em; }
  :global .DayPicker-Weekday abbr[title] {
    border-bottom: none;
    text-decoration: none; }
  :global .DayPicker-Body {
    display: table-row-group; }
  :global .DayPicker-Week {
    display: table-row; }
  :global .DayPicker-Day {
    display: table-cell;
    padding: 0.5em;
    border-radius: 50%;
    vertical-align: middle;
    text-align: center;
    cursor: pointer; }
  :global .DayPicker-WeekNumber {
    display: table-cell;
    padding: 0.5em;
    min-width: 1em;
    border-right: 1px solid #EAECEC;
    color: #8B9898;
    vertical-align: middle;
    text-align: right;
    font-size: 0.75em;
    cursor: pointer; }
  :global .DayPicker--interactionDisabled .DayPicker-Day {
    cursor: default; }
  :global .DayPicker-Footer {
    padding-top: 0.5em; }
  :global .DayPicker-TodayButton {
    border: none;
    background-color: transparent;
    background-image: none;
    box-shadow: none;
    color: var(--main-purple);
    font-size: 0.875em;
    cursor: pointer; }
  :global .DayPicker-Day--today {
    color: #D0021B;
    font-weight: 700; }
  :global .DayPicker-Day--outside {
    color: #8B9898;
    cursor: default; }
  :global .DayPicker-Day--disabled {
    color: #DCE0E0;
    cursor: default;
    /* background-color: #eff1f1; */ }
  :global .DayPicker-Day--sunday {
    background-color: #F7F8F8; }
  :global .DayPicker-Day--sunday:not(.DayPicker-Day--today) {
    color: #DCE0E0; }
  :global .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    position: relative;
    background-color: var(--main-purple);
    color: #F0F8FF; }
  :global .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
    background-color: var(--main-purple); }
  :global .DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
    background-color: #F0F8FF; }
  :global input.type2 {
    font-family: Roboto, sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-size: 21px;
    line-height: 1.75rem;
    font-weight: 400;
    letter-spacing: .00937em;
    box-sizing: border-box;
    width: auto;
    height: 100%;
    padding: 20px 12px 12px;
    transition: opacity 180ms cubic-bezier(0.4, 0, 0.2, 1);
    border-bottom: 1px solid;
    border-radius: 0;
    background: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: transparent;
    padding-left: 0;
    padding-top: 6px;
    /* text */
    font-family: "Open Sans", sans-serif;
    color: black;
    font-size: 21px;
    letter-spacing: 0.81px; }
    :global input.type2::placeholder {
      font-weight: normal;
      color: #7b7777;
      opacity: 0.45;
      font-size: 21px;
      letter-spacing: 0.81px; }
    :global input.type2:focus {
      outline: none; }
  :global input {
    font-family: Roboto, sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-size: 1rem;
    line-height: 1.75rem;
    font-weight: 400;
    letter-spacing: .00937em;
    text-decoration: inherit;
    text-transform: inherit;
    align-self: flex-end;
    box-sizing: border-box;
    width: auto;
    height: 100%;
    padding: 20px 12px 6px;
    transition: opacity 180ms cubic-bezier(0.4, 0, 0.2, 1);
    border: none;
    border-bottom: 1px solid;
    border-radius: 0;
    background: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: transparent;
    padding-left: 0;
    padding-top: 6px;
    border-bottom: none;
    background-image: linear-gradient(to right, var(--mdc-theme-primary) 50%, rgba(255, 255, 255, 0) 0%);
    background-position: bottom;
    background-size: 8px 2px;
    background-repeat: repeat-x;
    height: auto;
    /* text */
    font-family: "Open Sans", sans-serif;
    color: var(--mdc-theme-primary);
    font-size: 26px;
    font-weight: 700;
    letter-spacing: 0.5px; }
    :global input::placeholder {
      color: var(--mdc-theme-primary); }
    :global input:focus {
      outline: none; }
  :global .algolia-places {
    width: 100%;
    padding-bottom: 10px; }
  :global .ap-input,
  :global .ap-hint {
    width: 100%;
    padding-left: 0;
    padding-top: 12px;
    padding-bottom: 3px;
    height: 100%;
    border: none;
    border-radius: 0;
    font-size: 26px;
    font-weight: 700;
    letter-spacing: 0.5px;
    vertical-align: baseline !important;
    text-overflow: ellipsis; }
  :global .ap-input:hover ~ .ap-input-icon svg, :global .ap-input:focus ~ .ap-input-icon svg, :global .ap-input-icon:hover svg {
    fill: var(--rosy-pink); }
  :global .ap-input-icon {
    right: 10px; }
    :global .ap-input-icon svg {
      fill: var(--rosy-pink); }
    :global .ap-input-icon:hover svg {
      fill: var(--rosy-pink); }
  :global .DayPickerInput {
    display: inline-block;
    max-width: 265px;
    background-image: url("/static/images/cal-icn.svg");
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center right 15px; }
    :global .DayPickerInput input {
      width: 100%; }
  :global .DayPickerInput-OverlayWrapper {
    position: absolute;
    width: 550px; }
  :global .DayPickerInput-Overlay {
    position: absolute;
    right: 0;
    z-index: 1;
    background: white;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15); }
  :global .InputFromTo .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
    background-color: #f0f8ff !important;
    color: var(--mdc-theme-primary); }
  :global .InputFromTo .DayPicker-Day {
    border-radius: 0 !important; }
  :global .InputFromTo .DayPicker-Day--start {
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important; }
  :global .InputFromTo .DayPicker-Day--end {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important; }
  :global .InputFromTo .DayPickerInput-Overlay {
    width: 100%; }
  :global .InputFromTo-to .DayPickerInput-OverlayWrapper {
    margin-left: -260px; }

body ::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 6px;
  height: 6px; }

body ::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 0; }

body ::-webkit-scrollbar-thumb {
  cursor: pointer;
  border-radius: 5px;
  background: #e6e6e6;
  -webkit-transition: color .2s ease;
  transition: color .2s ease; }

body ::-webkit-scrollbar-thumb:window-inactive {
  background: #e6e6e6; }

body ::-webkit-scrollbar-thumb:hover {
  background: var(--main-purple);
  opacity: 0.5; }
